<template>
  <div v-show="isAllowed">
    <div v-if="bookingInfo.pm_uuid === null" @click="openModal" class="btn btn-primary--inverse">
      <img src="@/assets/img/icons/chat.svg" />
      <span>{{ buttonName }}</span>
    </div>
    <router-link v-else :to="`/inbox/${bookingInfo.pm_uuid}`" class="btn btn-primary--inverse">
      <img src="@/assets/img/icons/chat.svg" />
      <span>{{ buttonName }}</span>
    </router-link>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'booking-handover-btn',
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'CONTACT',
      actionType: 'BOOKING',
    };
  },
  computed: {
    loginUser() {
      return this.$store.state.global.user;
    },
  },
  methods: {
    async openModal() {
      this.$modal.show('modal-contact', {
        listingUuid: this.bookingInfo.listing_uuid,
        applicantId: this.bookingInfo.applicant_id,
        toOwner: this.loginUser.id === this.bookingInfo.applicant_id,
        listingType: this.bookingInfo.listing_type,
      });
    },
  },
};
</script>

<style scoped></style>
